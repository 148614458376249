


















































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Machine} from "@/models/machine";
import {MachineImage} from "@/models/machineImage";
import {MachineImageType} from "@/models/maintenance";
import ImageRepository from "@/api/ImageRepository";
import {RepositoryFactory} from "@/api/RepositoryFactory";

const imageRepository: ImageRepository = RepositoryFactory.get('image');

@Component({
  components: {
    ImageCardComponent: () => import('./../ImageCard.component.vue'),
  }
})

export default class InventoryImageGalleryComponent extends Vue {

  @Prop({default: () => new Machine({})})
  public machine!: Machine;

  public dragover: boolean = false;
  public uploadedFiles: Array<any> = [];

  public imageTypes: any[] = [];

  public mounted() {
    this.imageTypes = Object.values(MachineImageType);
    this.imageTypes = this.imageTypes.filter(type => type !== 'imageMaintenance');
    this.imageTypes = this.imageTypes.map(type => {
      return {
        value: type, text: type.replace('image', '')
      };
    })
  }

  public removedImage(image: MachineImage) {
    this.machine.imageMontageansicht = this.machine.imageMontageansicht.filter(img => img.id !== image.id);
    this.machine.imageSteuerung = this.machine.imageSteuerung.filter(img => img.id !== image.id);
    this.machine.imageTypenschild = this.machine.imageTypenschild.filter(img => img.id !== image.id);
    this.machine.imageDefekteBauteile = this.machine.imageDefekteBauteile.filter(img => img.id !== image.id);
    this.machine.imageBauteileSchrank = this.machine.imageBauteileSchrank.filter(img => img.id !== image.id);
  }

  removeFile(filename: string) {
    // Find the index of the
    const index = this.uploadedFiles.findIndex(
        file => file.name === filename
    );
    // If file is in uploaded files remove it
    if (index > -1) this.uploadedFiles.splice(index, 1);
  }

  onDrop(dropEvent: any) {
    this.dragover = false;
    // If there are already uploaded files remove them
    if (this.uploadedFiles.length > 0)
      this.uploadedFiles = [];
    else {
      const files = Array.from(dropEvent.dataTransfer.files);
      files.forEach((element: any) => {
            element.imageType = MachineImageType.imageMontageansicht;
            element.url = URL.createObjectURL(element);
            this.uploadedFiles.push(element)
          }
      );
    }
  }

  public async submit() {
    try {
      const promises = this.uploadedFiles.map(file =>
          imageRepository.uploadImage(file, file.imageType, {
            machine: this.machine,
            maintenance: null
          }));
      const responses = await Promise.all(promises);
      responses.forEach((imageResponse, index) => {

        if (this.uploadedFiles[index] && this.uploadedFiles[index].imageType) {
          switch (this.uploadedFiles[index].imageType) {
            case MachineImageType.imageMontageansicht.toString():
              this.machine.imageMontageansicht.push(new MachineImage(imageResponse.data, MachineImageType.imageMontageansicht));
              break;
            case MachineImageType.imageSteuerung:
              this.machine.imageSteuerung.push(new MachineImage(imageResponse.data, MachineImageType.imageSteuerung));
              break;
            case MachineImageType.imageBauteileSchrank:
              this.machine.imageBauteileSchrank.push(new MachineImage(imageResponse.data, MachineImageType.imageBauteileSchrank));
              break;
            case MachineImageType.imageTypenschild:
              this.machine.imageTypenschild.push(new MachineImage(imageResponse.data, MachineImageType.imageTypenschild));
              break;
            case MachineImageType.imageDefekteBauteile:
              this.machine.imageDefekteBauteile.push(new MachineImage(imageResponse.data, MachineImageType.imageDefekteBauteile));
              break;
          }
        }
        // this.maintenance.maintenanceImages.push(new MachineImage(imageResponse.data, MachineImageType.imageMaintenance));
      });
      this.uploadedFiles = [];
    } catch (e) {
      console.log('error');
    }

  }
}
